<style lang="scss" scoped></style>
<template>
    <a-spin size="large" :spinning="spinning">
        <div class="consultingManagement">
            <ds-header title="企业展示管理"></ds-header>
            <a-form layout="inline">
                <a-form-item label="企业名称">
                    <a-input v-model="search.enterpriseName" :maxLength="50" placeholder="请输入企业名称" />
                </a-form-item>
                <a-form-model-item label="所在项目">
                    <el-cascader v-model="search.projectIdList" style="width: 200px" :options="projectList"
                        :collapse-tags="true"
                        :props="{ emitPath: false, multiple: true, checkStrictly: true, label: 'project_name', children: 'child', value: 'id' }"
                        size="small" clearable></el-cascader>
                </a-form-model-item>
                <span>
                    <a-button type="primary" style="margin-right: 20px;" @click="getList('search')">
                        <a-icon type="search" />
                        查询
                    </a-button>
                    <a-button @click="reset">
                        重置
                    </a-button>
                </span>
            </a-form>
            <div>
                <a-button type="primary" @click="getindustryTree">
                    <a-icon type="plus" />
                    添加
                </a-button>
            </div>
            <div style="margin-top: 10px">
                <a-table bordered :rowKey="(record, index) => record.id" :childrenColumnName="'child_list'"
                    :columns="table.columns" :data-source="table.data" @change="pageChange" :pagination="pagination"
                    :scroll="{ x: 'max-content' }">
                    <span slot="action" slot-scope="text, record">
                        <a-button  type="link" @click="handleEdit(record)">编辑</a-button>
                        <deletebut tipsTitle="删除企业" tipsContent="确认要删除该企业吗？" @deleteBut="handleDelMenu(record)"
                            :record="record">
                        </deletebut>
                    </span>
                </a-table>
            </div>
        </div>
        <a-modal v-model="addShow" :title="title" @cancel='handleAddClose' @ok="handleAddOk" :maskClosable="false">
            <a-form class="formModel" layout="horizontal">
                <a-form-item label="企业名称" required>
                    <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" show-search
                        v-model="add.enterpriseId" placeholder="输入企业名称以选择" :default-active-first-option="false"
                        :show-arrow="false" :filter-option="false" :not-found-content="null"
                        @search="getEnterpriseProjectList" @select="selectEnterPrise">
                        <a-select-option v-for="item in enterpriseMsg" :key="item.enterpriseId">
                            {{ item.enterpriseName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="所属项目" required>
                    <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple"
                        :maxTagCount="1" :maxTagTextLength="5" placeholder="请选择所属项目" v-model="add.projectIdList">
                        <a-select-option v-for="item in EprojectList" :key="item.projectId">
                            {{ item.projectName }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>

    </a-spin>
</template>

<script type="text/babel">
/**
 * TODO fileOverview
 * --------------
 * @author xiaoF
 * @since 2021/6/1.
 */
import * as api from "@/api/enterpriseDisplayManagement/index.js";
import moment from "moment";
import deletebut from "@/components/delete/index";

export default {
    name: "index",
    components: {
        deletebut
    },
    data() {
        return {
            title: '新增展示企业',
            parent_industry_id: null,//添加或修改的父级id
            activeKey: 1,
            pagination: {
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total, range) => {
                    return `共${total}条`
                }
            },
            search: {
                enterpriseName: '',
                projectIdList: []
            },
            table: {
                columns: [
                    {
                        title: "公司名称",
                        dataIndex: "enterpriseName",
                        key: "enterpriseName",
                        scopedSlots: { customRender: "enterpriseName" },
                        width: 350,
                        ellipsis: true,
                    },
                    {
                        title: "入驻项目",
                        dataIndex: "projectName",
                        key: "projectName",
                        scopedSlots: { customRender: "projectName" },
                        width: 350,
                        ellipsis: true,
                    },
                    {
                        title: "创建人",
                        dataIndex: "createName",
                        key: "createName",
                        width: 'auto',
                    },
                    {
                        title: "创建时间",
                        dataIndex: "gmtCreate",
                        key: "gmtCreate",
                        customRender: (text, row, index) => {
                            return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : '-';
                        },
                        width: 'auto',
                    },
                    {
                        title: "操作",
                        key: "action",
                        className: "action-ql-list",
                        scopedSlots: { customRender: "action" },
                        fixed: 'right',
                        width: 200,
                    },
                ],
                data: [],
            },
            spinning: false,
            projectList: [],
            addShow: false,
            add: {
                enterpriseId: undefined,
                projectIdList: [],
            },
            enterpriseName: '',
            enterpriseMsg: [],// 新增企业
            EprojectList: [],// 新增企业
        };
    },
    created() {
        this.getList();
        this.getproject();
    },
    methods: {
        reset() {
            this.search.enterpriseName = '';
            this.search.projectIdList = [];
            this.pagination.current = 1;
            this.pagination.pageSize = 10;
            this.getList();
        },
        async getindustryTree() {
            this.$router.push({path:'/enterpriseDisplayManagement/addEnterpriseDisplay'})
            // this.addShow = true;
        },
        handleAddOk(params) {
            api.addShowEnterprise(this.add).then((res) => {
                console.log(res, '==============>');
                if (res.code === "200") {
                    this.addShow = false;
                    this.$message.success("添加成功");
                    this.getList();
                    this.handleAddClose()
                } else {
                    this.$message.error(res.msg);
                }
            });

        },
        handleAddClose(params) {
            this.addShow = false;
            this.add ={
                enterpriseId: undefined,
                projectIdList: [],
            }
            this.EprojectList = []
            this.enterpriseMsg = []
        },
        pageChange(pageOption) {
            this.pagination.current = pageOption.current
            this.pagination.pageSize = pageOption.pageSize
            this.getList()
        },
        getList(val) {
            if (val) {
                this.pagination.current = 1
                this.pagination.pageSize = 10
            }
            this.spinning = true
            api.getEnterpriseList({
                ...this.search,
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
                .then((res) => {
                    this.spinning = false
                    console.log(res, '==========>');
                    if (res.code == '200') {
                        this.table.data = res.data.list;
                        this.pagination.total = res.data.total
                    } else {
                        this.$message.error(res.msg)
                    }
                });
        },
        handleDelMenu(record) {
            api.delShowEnterprise(record.id).then((res) => {
                if (res.code === "200") {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        async getproject() {
            let res = await api.getShunyiAllProject({ check_type: true })
            this.projectList = res.data
        },
        async getEnterpriseProjectList(val) {
            let params = {
                "enterpriseName": val,
                "page": 0,
                "size": 100,
            }
            let res = await api.getEnterpriseProjectList(params)
            this.enterpriseMsg = res.data.enterpriseList
            console.log(res, '==================>');
        },
        //选择企业
        selectEnterPrise(value) {
            this.getProject(value);
        },
        // 获取所属项目
        getProject(id) {
            this.enterpriseMsg.forEach((item) => {
                if (item.enterpriseId === id) {
                    this.EprojectList = item.projectList;
                }
            });
        },
        handleEdit(data){
            this.$router.push({path:'/enterpriseDisplayManagement/addEnterpriseDisplay',query:{id:data.id}})
        }
    },
};
</script>
<style scoped lang="scss">
.productName {
    display: -webkit-box;
    /* 需要对老版本的WebKit浏览器做兼容 */
    display: -ms-box;
    /* 对IE10的支持 */
    display: box;
    -webkit-line-clamp: 1;
    /* 设置显示的行数 */
    -webkit-box-orient: vertical;
    /* 设置布局方向为垂直 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
}
</style>